import React, { useState } from 'react';

const MultiCheckDropdown = () => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const options = ['All', 'Chesmar', 'CitySide', 'David Weekly - Austin', 'David Weekly - Dallas'];

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (option) => {
    if (option === 'All') {
      // If "All" is clicked, select all other options if not already selected
      if (selectedItems.length === options.length - 1) {
        // If all options except "All" are selected, unselect everything
        setSelectedItems([]);
      } else {
        // Otherwise, select all options except "All"
        setSelectedItems(options.filter(item => item !== 'All'));
      }
    } else {
      // For other checkboxes, add or remove based on current selection
      if (selectedItems.includes(option)) {
        setSelectedItems(selectedItems.filter(item => item !== option));
      } else {
        setSelectedItems([...selectedItems, option]);
      }
    }
  };

  // Handle the state of "All" based on individual checkboxes
  const isAllSelected = selectedItems.length === options.length - 1;

  return (
    <div className="self-evaluation-dd">
      <div onClick={handleToggle} className={`self-evaluation-trigger ${isOpen ? 'active' : ''}`}>
        {selectedItems.length > 0 ? selectedItems.join(', ') : 'All'}
      </div>
      {isOpen && (
        <div className='self-evaluation-option'>
          {options.map((option) => (
            <label key={option}>
              <input
                type="checkbox"
                checked={option === 'All' ? isAllSelected : selectedItems.includes(option)}
                onChange={() => handleCheckboxChange(option)}
              />
              {option}
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiCheckDropdown;

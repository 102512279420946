
import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
const LineChart = ({ view }) => {  
  const chartRef = useRef();
  const [chartWidth, setChartWidth] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current) {
        const parentWidth = chartRef.current.getBoundingClientRect().width;
        setChartWidth(parentWidth);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const data = [
      { date: "2024-01-01", value: 100, testcase: 72 },
      { date: "2024-02-01", value: 90 , testcase: 72},
      { date: "2024-03-01", value: 100, testcase: 72 },
      { date: "2024-04-01", value: 85 , testcase: 72},
      { date: "2024-05-01", value: 80 , testcase: 72},
      { date: "2024-06-01", value: 75 , testcase: 72},
      { date: "2024-07-01", value: 60 , testcase: 72},
      { date: "2024-08-01", value: 45 , testcase: 72},
      { date: "2024-09-01", value: 60 , testcase: 72},
      { date: "2024-10-01", value: 95 , testcase: 72},
      { date: "2024-11-01", value: 70 , testcase: 72},
      { date: "2024-12-01", value: 60 , testcase: 72}
    ];
  
    const margin = { top: 50, right: 30, bottom: 30, left: 50 };
    const height = 400 - margin.top - margin.bottom;
  
    if (chartWidth === 0) return; // Exit if the width is 0
  
    // Clear existing SVG
    d3.select(chartRef.current).selectAll("*").remove();
  
    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", chartWidth - margin.left - margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);
  
    const x = d3
      .scaleTime()
      .domain(d3.extent(data, (d) => new Date(d.date)))
      .range([0, chartWidth - margin.left - margin.right]);
  
    const y = d3
      .scaleLinear()
      .domain([0, 100]) // Explicit domain to include 0 to 100 range
      .range([height, 0]);
  
    // Axes
    svg
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x).tickFormat(d3.timeFormat("%b")).ticks(12));
  
    // Manually set y-axis ticks to 25, 50, 75, 100
    svg
      .append("g")
      .call(d3.axisLeft(y).tickValues([0, 25, 50, 75, 100])); // Set specific tick values
  
    // Horizontal lines for levels 25, 50, 75, 100
    const levels = [0, 25, 50, 75, 100];
    svg
      .selectAll(".level-line")
      .data(levels)
      .enter()
      .append("line")
      .attr("class", "level-line")
      .attr("x1", 0)
      .attr("y1", (d) => y(d))
      .attr("x2", chartWidth - margin.left - margin.right)
      .attr("y2", (d) => y(d))
      .attr("stroke", "gray")
      .attr("stroke-dasharray", "4")
      .attr("stroke-width", 1);
  
    // Line generator
    const line = d3
      .line()
      .x((d) => x(new Date(d.date)))
      .y((d) => y(d.value));
  
    // Draw line
    svg
      .append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "#C29C61")
      .attr("stroke-width", 3)
      .attr("d", line);
  
    // Draw data points
    svg
      .selectAll("dot")
      .data(data)
      .enter()
      .append("circle")
      .attr("cx", (d) => x(new Date(d.date)))
      .attr("cy", (d) => y(d.value))
      .attr("r", 4)
      .attr("fill", "#fff")
      .attr("stroke", "#C29C61")
      .attr("stroke-width", 3);
  
    // Tooltip logic
    let tooltipTimeout;
    let tooltipState = 0;
    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("position", "fixed")
      .style("visibility", "hidden")
      .html('<button id="viewDetailsBtn">View Details</button>');
  
    svg
      .selectAll("circle")
      .on("mouseover", (event, d) => {
        const cx = event.clientX - 30;
        const cy = event.clientY - 90;
  
        tooltip
          .style("visibility", "visible")
          .style("top", `${cy}px`)
          .style("left", `${cx}px`);
        tooltip.html(
          `<strong>${d3.timeFormat("%d %b %Y")(new Date(d.date))}</strong>Score: <span>${d.value}</span><br>Test Cases Failed: <span>${d.testcase}</span> <button id="viewDetailsBtn">View</button>`
        );
  
        // Attach click handler to the button within the tooltip
        d3.select("#viewDetailsBtn")
          .on("click", (e) => {
            if (view) {
              view(e); // Call the passed view function
            }
          });
      })
      .on("mouseout", () => {
        tooltipState = 0;
        tooltipTimeout = setTimeout(() => {
          if (tooltipState === 0) {
            tooltip.style("visibility", "hidden");
          }
        }, 1000);
      });
  
    tooltip
      .on("mouseover", () => {
        clearTimeout(tooltipTimeout);
        tooltipState = 1;
        tooltip.style("visibility", "visible");
      })
      .on("mouseout", () => {
        tooltipState = 0;
        tooltip.style("visibility", "hidden");
      });
  }, [chartWidth, view]);
  return <div ref={chartRef}></div>;
};
export default LineChart;

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import profileIcon from '../../assets/images/profile-icon.svg';
import { logoutUser } from "../../api";

function Header() {
    const location = useLocation();
    const isActive = (path) => location.pathname === path;
    const email = localStorage.getItem('loggedInUserEmail');
    const isAdmin = localStorage.getItem('is_admin') === 'true'; // Check if the user is an admin

    const handleLogout = async () => {
        try {
            await logoutUser(email);
            // Clear local storage
            localStorage.removeItem('authToken');
            localStorage.removeItem('loggedInUserEmail');
            localStorage.removeItem('is_admin');

            // Redirect to login screen
            window.location.href = '/login';
        } catch (error) {
            alert('Logout failed. Please try again.');
        }
    };

    return (
        <header>
            <div className="po2frm-header-inner">
                <nav>
                    <ul className="po2frms-menu">

                        

                        {isAdmin && (
                            <>
                                <li>
                                    <Link to={"/dashboard"} className={isActive("/dashboard") ? "active" : ""}>
                                        Dashboard
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/users"} className={isActive("/users") ? "active" : ""}>
                                        Users
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/builder-rules"} className={isActive("/builder-rules") ? "active" : ""}>
                                        Builder Rules
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/orders-lisiting"} className={isActive("/orders-lisiting") ? "active" : ""}>
                                    Order Listing
                                    </Link>
                                </li>
                            </>
                        )}

                    </ul>
                </nav>
                <div className="po2frms-actions">
                    <div className="pof2frms-dd">
                        <a href="#" className="targeted-element">
                            Self Evaluation <span className="pof2frms-tags">93</span>
                        </a>
                        <ul className="pof2frms-dropdown">
                            <li><span>Last evaluated: 11/06/2024</span></li>
                            <li><Link data-numbers="93" to="#">Start Self Evaluation</Link></li>
                            <li><Link data-numbers="999" to={isAdmin ? "/test-cases/":'#'}>Test Cases</Link></li>
                            <li><Link data-numbers="111" to={isAdmin ? "/self-evaluation-history/": '#'}>Self evaluation history</Link></li>
                        </ul>
                    </div>
                    <div className="pof2frms-dd">
                        <a href="#" className="targeted-element">
                            <span className="user-avatar">
                                <img src={profileIcon} alt={email} />
                            </span>
                            {email}
                        </a>
                        <ul className="pof2frms-dropdown">
                            <li><a href="#" onClick={handleLogout}>Logout</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
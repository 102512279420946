import React from 'react';
import { Navigate } from 'react-router-dom';
import { isAuthenticated } from '../utils/auth';

const PublicRoute = ({ children }) => {
  if (isAuthenticated()) {
    // Redirect to users page if already authenticated
    return <Navigate to="/users" />;
  }
  return children;
};

export default PublicRoute;

import React, { useState, useEffect } from 'react';
import Header from "./common/Header";
import { getAllTestCases, deleteTestCase } from '../api';

const TextCases = () => {
    const [isDeletePopupVisible, setDeletePopupVisible] = useState(false);
    const [data, setData] = useState([]);
    const [testCaseToDelete, setTestCaseToDelete] = useState(null); // Track which test case is being deleted

    const handleDeleteClick = (e, testCaseName) => {
        e.preventDefault();
        setTestCaseToDelete(testCaseName); // Set the test case to delete
        setDeletePopupVisible(true); // Show the delete confirmation popup
    };

    const closeDeletePopup = (e) => {
        e.preventDefault();
        setDeletePopupVisible(false); // Close the popup without deleting
        setTestCaseToDelete(null); // Reset the test case to delete
    };

    const confirmDelete = async () => {
        if (!testCaseToDelete) return; // Make sure there is a test case to delete
        try {
            // Call the delete API with the test case name
            await deleteTestCase(testCaseToDelete);
            // Remove the deleted test case from the state
            setData((prevData) => prevData.filter((testCase) => testCase.batch !== testCaseToDelete));
            setDeletePopupVisible(false); // Hide the delete confirmation popup
            setTestCaseToDelete(null); // Clear the test case being deleted
        } catch (error) {
            console.error("Error deleting test case:", error);
        }
    };

    // Fetch test cases on component mount
    useEffect(() => {
        const fetchTestCases = async () => {
            try {
                const testCases = await getAllTestCases();
                // Format the test case data to match the required structure
                const formattedData = testCases.map((testCase, index) => ({
                    id: index + 1,
                    batch: testCase.batch_name, // The batch name is used as a unique identifier
                    date: testCase['date-added'],
                    description: testCase.description,
                }));
                setData(formattedData);
            } catch (error) {
                console.error("Error fetching test cases:", error);
            }
        };

        fetchTestCases();
    }, []);

    return (
        <div>
            <svg style={{display: 'none'}}>
			<symbol id="icon-plus" width="14" height="14" viewBox="0 0 14 14" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M13.4167 6.12439H7.87558V0.583319C7.87558 0.42873 7.81399 0.28018 7.70408 0.170289C7.59418 0.0615947 7.44684 0 7.29226 0H6.70773C6.38646 0 6.1244 0.260868 6.1244 0.583325V6.1244H0.583325C0.260853 6.1244 0 6.38648 0 6.70773V7.29226C0 7.44684 0.0615941 7.59419 0.170289 7.70408C0.280192 7.81398 0.428743 7.87558 0.583319 7.87558H6.1244V13.4179V13.4167C6.1244 13.5713 6.18599 13.7198 6.29589 13.8297C6.4058 13.9384 6.55314 14 6.70772 14H7.29225C7.44684 14 7.59418 13.9384 7.70407 13.8297C7.81398 13.7198 7.87557 13.5713 7.87557 13.4167V7.87561H13.4179H13.4167C13.5713 7.87561 13.7198 7.81401 13.8297 7.70411C13.9384 7.5942 14 7.44686 14 7.29228V6.70775C14 6.55316 13.9384 6.40582 13.8297 6.29593C13.7198 6.18603 13.5712 6.12439 13.4167 6.12439Z"
                            fill="currentColor"/>
                    </symbol>
				<symbol id="trash-icon" width="20" height="20" viewBox="0 0 20 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8.85686 7.20209C8.85708 6.95367 8.762 6.762 8.61534 6.63331C8.47013 6.5059 8.27874 6.4439 8.08993 6.4439C7.90111 6.4439 7.70968 6.5059 7.56439 6.63329C7.41764 6.76196 7.3224 6.9536 7.3224 7.20203V15.1881C7.3224 15.4367 7.41756 15.6285 7.56424 15.7573C7.70947 15.8848 7.90084 15.9468 8.08963 15.9468C8.27842 15.9468 8.46979 15.8848 8.61502 15.7573C8.7617 15.6285 8.85686 15.4367 8.85686 15.1881V7.20209ZM8.85686 7.20209L8.78186 7.20203H8.85686V7.20209ZM4.26882 3.31495H4.26879L2.4972 3.31576C2.49718 3.31576 2.49717 3.31576 2.49716 3.31576C2.24859 3.31577 2.05682 3.41093 1.92805 3.5576C1.80054 3.70283 1.73848 3.8942 1.73848 4.08299C1.73848 4.27178 1.80054 4.46315 1.92805 4.60838C2.05683 4.75506 2.24861 4.85022 2.4972 4.85022H3.50172L3.50094 15.6307C3.50094 17.5271 5.04884 19.075 6.94519 19.075H13.0002C14.8965 19.075 16.4444 17.5263 16.4444 15.6307V4.84941H17.4498C17.6983 4.84941 17.8901 4.75425 18.0189 4.60757C18.1464 4.46234 18.2085 4.27097 18.2085 4.08218C18.2085 3.89339 18.1464 3.70202 18.0189 3.55679C17.8901 3.41011 17.6983 3.31495 17.4498 3.31495H12.8298C12.7226 2.7207 12.434 2.18796 12.0235 1.77741C10.3465 0.099754 7.52116 1.0479 7.11598 3.31495H4.26882ZM11.0885 7.20203V15.1881C11.0885 15.4367 11.1836 15.6285 11.3303 15.7573C11.4755 15.8848 11.6669 15.9468 11.8557 15.9468C12.0445 15.9468 12.2358 15.8848 12.3811 15.7573C12.5278 15.6285 12.6229 15.4367 12.6229 15.1881V7.20203C12.6229 6.95362 12.5277 6.76197 12.381 6.6333C12.2358 6.5059 12.0445 6.4439 11.8557 6.4439C11.6669 6.4439 11.4755 6.5059 11.3303 6.6333C11.1836 6.76197 11.0885 6.95362 11.0885 7.20203ZM11.2406 3.31495H8.70568C9.15882 2.18355 10.7874 2.18355 11.2406 3.31495ZM14.9101 4.8502L14.9107 15.6315C14.9107 16.6807 14.0501 17.5414 13.0009 17.5414H6.94588C5.89669 17.5414 5.03604 16.68 5.03604 15.6315V4.8502H14.9101Z"
                            fill="#6D727A" stroke="#6D727A" strokeWidth="0.15"/>
                    </symbol></svg>
            <Header />
            <main>
                <div className="pof2frms-container">
                    <div className="pof2frms-head">
                        <h1>Test Cases</h1>
                        <a href="/add-test-cases">
                            <button className="btn" data-open="#add-test-cases">
                                <svg width="14" height="14">
                                    <use href="#icon-plus"></use>
                                </svg> 
                                Add Test Case
                            </button>
                        </a>
                    </div>
                    <div className="po2frms-table">
                        <table>
                            <thead>
                            <tr>
                                <th>Batch Name</th>
                                <th>Date Added</th>
                                <th>Description</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.map((item) => (
                                <tr key={item.id}>
                                    <td>{item.batch}</td>
                                    <td>{item.date}</td>
                                    <td>{item.description}</td>
                                    <td>
                                        <a href="#delete-file" data-tooltip="Delete"
                                           onClick={(e) => handleDeleteClick(e, item.batch)}>
                                            <svg width="20" height="20">
                                                <use href="#trash-icon"></use>
                                            </svg>
                                        </a>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </main>
            {isDeletePopupVisible && (
                <div className="pof2frms-popup-wrapper" id="delete-file">
                    <div className="popup-overlay" onClick={closeDeletePopup}></div>
                    <div className="pof2frms-popup-container">
                        <a href="#" className="pof2frms-popup-close" aria-label="close-popup"
                           onClick={closeDeletePopup}></a>
                        <h3>Delete Test Case</h3>
                        <p>Are you sure that you want to delete test case ‘{testCaseToDelete}’?</p>
                        <div className="pof2frms-popup-action">
                            <button type="button" className="pof2frms-cancel" onClick={closeDeletePopup}>
                                Cancel
                            </button>
                            <button type="button" className="pof2frms-deactivate" onClick={confirmDelete}>
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default TextCases;

import React, {useState, useEffect, useCallback} from 'react';
import Header from "./common/Header";
import {addUser, changeUserRole, changeUserStatus, fetchUsers} from "../api";

function Users() {
    const [users, setUsers] = useState([]);
    const [showInactive, setShowInactive] = useState(false);
    const [currentUser, setCurrentUser] = useState(localStorage.getItem('loggedInUserEmail'));
    const [popupData, setPopupData] = useState(null); // { type: 'deactivate' | 'role', user }
    const [showAddUserPopUp, setAddUserPopUp] = useState(false);

    const handleUserAddClick = () => {
        setAddUserPopUp(true);
    };

    const handleUserAddCancel = () => {
        setAddUserPopUp(false);
    };

    const handleUserAddConfirm = async (userData) => {
        setAddUserPopUp(false);
        try {
            const message = await addUser(userData.email, userData.admin, currentUser);
            alert(message.message);
            fetchAllUsers(); // Ensure fetchAllUsers is defined to refresh the list
        } catch (error) {
            alert(`Failed to add user: ${error.message}`);
        }
    };


    // Fetch users from the server
    const fetchAllUsers = useCallback(async () => {
        const data = await fetchUsers();
        if (data) {
            setUsers(data);
        }
    }, []);

    useEffect(() => {
        fetchAllUsers();
    }, [fetchAllUsers]);

    // Handle the status change of a user (active/inactive)
    const handleStatusChange = (user, e) => {
        const newStatus = e.target.value === "active";
        setPopupData({type: newStatus ? "activate" : "deactivate", user});
    };

    const handleRoleChange = async (email, newRole) => {
        if (email === currentUser) {
            alert("You cannot change your own role.");
            return;
        }

        try {
            const message = await changeUserRole(email, newRole);
            alert(message);
            fetchAllUsers(); // Ensure fetchAllUsers is defined to refresh the list
        } catch (error) {
            alert(`Failed to change role: ${error.message}`);
        }
    };

    const handleUserStatus = async (email, status) => {
        if (email === currentUser) {
            alert("You cannot update yourself.");
            return;
        }

        try {
            const message = await changeUserStatus(email, status);
            alert(message);
            fetchAllUsers(); // Ensure fetchAllUsers is defined to refresh the list
        } catch (error) {
            alert(`Failed to update user status: ${error.message}`);
        }
    };

    // Filter the users based on active/inactive status
    const filteredUsers = users.filter(user => showInactive || user.is_active);

    // Handle the popup confirm (deactivate or change role)
    const handlePopupConfirm = () => {
        if (!popupData) return;

        const {type, user} = popupData;
        if (type === "deactivate" || type === "activate") {
            handleUserStatus(user.email, user.is_active); // Activate or Deactivate user
        } else if (type === "role") {
            handleRoleChange(user.email, user.newRole); // Change the user's role
        }
        setPopupData(null); // Reset popup data after confirmation
    };

    // Handle cancel button on the popup
    const handlePopupCancel = () => {
        setPopupData(null); // Reset popup data on cancel
    };

    return (
        <div>
            <Header/>
            <main>
                <div className="pof2frms-container">
                    <div className="pof2frms-head">
                        <h1>Users</h1>
                        <button className="btn" onClick={handleUserAddClick}>
                            <svg width="14" height="14">
                                <use href="#icon-plus"></use>
                            </svg>
                            &nbsp;Add User
                        </button>
                    </div>
                    <div className="po2frms-table">
                        <table>
                            <thead>
                            <tr>
                                <th>Email</th>
                                <th>
                                    Status
                                    <label>
                                        <input
                                            type="checkbox"
                                            onChange={(e) => setShowInactive(e.target.checked)}
                                        />
                                        Show Inactive
                                    </label>
                                </th>
                                <th>Role</th>
                            </tr>
                            </thead>
                            <tbody>
                            {filteredUsers.map(user => (
                                <tr key={user.email}>
                                    <td>{user.email}</td>
                                    <td>
                                        <select
                                            value={user.is_active ? "active" : "inactive"}
                                            onChange={(e) => handleStatusChange(user, e)}
                                            disabled={user.email === currentUser}
                                        >
                                            <option value="active">Active</option>
                                            <option value="inactive">Inactive</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select
                                            value={user.is_admin ? "Admin" : "Analyst"}
                                            onChange={(e) =>
                                                setPopupData({
                                                    type: "role",
                                                    user: {email: user.email, newRole: e.target.value},
                                                })
                                            }
                                            disabled={user.email === currentUser}
                                        >
                                            <option value="Admin">Admin</option>
                                            <option value="Analyst">Analyst</option>
                                        </select>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </main>

            {popupData && (
                <Popup
                    title={popupData.type === "deactivate" || popupData.type === "activate" ? "Change User Status" : "Change Role"}
                    message={
                        popupData.type === "deactivate" || popupData.type === "activate"
                            ? `Are you sure you want to ${popupData.type} ${popupData.user.email}?`
                            : `Change role of ${popupData.user.email} to ${popupData.user.newRole}?`
                    }
                    onCancel={handlePopupCancel}
                    onConfirm={handlePopupConfirm}
                />
            )}

            {showAddUserPopUp && (
                <AddUserPopUp
                    title="ADD USER"
                    message="Please fill out the user information."
                    onCancel={handleUserAddCancel}
                    onConfirm={handleUserAddConfirm}
                />
            )}
        </div>
    );
}

// Popup Component
function Popup({title, message, onCancel, onConfirm}) {
    return (
        <div className="pof2frms-popup-wrapper">
            <div className="popup-overlay"/>
            <div className="pof2frms-popup-container">
                <h3>{title}</h3>
                <p>{message}</p>
                <div className="pof2frms-popup-action">
                    <button type="button" className="pof2frms-cancel" onClick={onCancel}>
                        Cancel
                    </button>
                    <button type="button" className="pof2frms-confirm" onClick={onConfirm}>
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
}

function AddUserPopUp({title, message, onCancel, onConfirm}) {
    const [email, setEmail] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);

    const handleRoleChange = (event) => {
        setIsAdmin(event.target.value === "admin");
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSubmit = () => {
        const userData = {
            email,
            admin: isAdmin,
        };
        onConfirm(userData);
    };

    return (
        <div className="pof2frms-popup-wrapper">
            <div className="popup-overlay"/>
            <div className="pof2frms-popup-container">
                <h3>{title}</h3>
                <p>{message}</p>
                <input
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={handleEmailChange}
                    required
                />
                <select onChange={handleRoleChange}>
                    <option value="analyst">Analyst</option>
                    <option value="admin">Admin</option>
                </select>
                <div className="pof2frms-popup-action">
                    <button type="button" className="pof2frms-cancel" onClick={onCancel}>
                        Cancel
                    </button>
                    <button type="button" className="pof2frms-confirm" onClick={handleSubmit}>
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
}


export default Users;
import React from 'react';

const StateBox = ({ number,unit, text, icon, color }) => {
  return (
    <div className="statusbox">
      <div className="status-box-content">
        <h3><span>{number}</span> <small>{unit}</small></h3>
        <p>{text}</p>
      </div>
      <div className={`status-icon ${color}`}>
        <img src={icon} alt={text} />
      </div>
    </div>
  );
};

export default StateBox;
const API_BASE_URL = 'https://eaglepoint.wpbrigade.com/auth';

// Generate magic link
export const generateMagicLink = async (email) => {
    try {
        const response = await fetch(`${API_BASE_URL}/generate-magic-link?email=${encodeURIComponent(email)}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.detail || 'Failed to generate magic link');
        }

        return await response.json();
    } catch (error) {
        console.error('Error generating magic link:', error);
        throw error;
    }
};




export const fetchUsers = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}/users`);
        if (!response.ok) {
            throw new Error(`Error fetching users: ${response.statusText}`);
        }
        return await response.json();
    } catch (error) {
        console.error("Error fetching users:", error);
    }
};

export const changeUserRole = async (email, newRole) => {
    try {
        const is_admin = newRole === "Admin";
        const response = await fetch(`${API_BASE_URL}/change-role?email=${email}&is_admin=${is_admin}`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.detail || `Error changing role: ${response.statusText}`);
        }

        return "Role updated successfully.";
    } catch (error) {
        console.error("Error changing role:", error);
        throw error;
    }
};


export const ProcessOrder = async () => {
    try {
      const response = await fetch("http://localhost:8000/process-order/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json", 
        },
        body: JSON.stringify({ }),
      });
  
      if (response.ok) {
        console.log("Process started successfully");
      } else {
        console.error("Error starting process:", response.statusText);
      }
    } catch (error) {
      console.error("Error starting process:", error);
    }
};


export const TestWebSocketLongRun = async (builder_name, specific_result,timestamp) => {
    try {
      const response = await fetch(`${API_BASE_URL}/call-compiler/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", 
        },
        body: JSON.stringify({ builder_name, specific_result,timestamp }),
      });
  
      if (response.ok) {
        // console.log("Process started successfully");
      } else {
        console.error("Error starting process:", response.statusText);
      }
    } catch (error) {
      console.error("Error starting process:", error);
    }
};

export const TestOrdersListingRun = async () => {
    try {
      const response = await fetch("http://localhost:8000/call-order-processing/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json", 
        },
        body: '',
      });
  
      if (response.ok) {
        // console.log("Process started successfully");
      } else {
        console.error("Error starting process:", response.statusText);
      }
    } catch (error) {
      console.error("Error starting process:", error);
    }
};

export const Scoring = async (builder_name,specific_result,timestamp) => {
    try {
      const response = await fetch(`${API_BASE_URL}/call-scoring/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Specify that you're sending JSON data
        },
        body: JSON.stringify({ builder_name,specific_result,timestamp }),
      });
  
      if (response.ok) {
        console.log("Process started successfully");
      } else {
        console.error("Error starting process:", response.statusText);
      }
    } catch (error) {
      console.error("Error starting process:", error);
    }
};

export const changeUserStatus = async (email, status) => {
    try {
        const response = await fetch(`${API_BASE_URL}/change-status?email=${email}&status=${!status}`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
        });

        if (!response.ok) {
            throw new Error(`Error updating user: ${response.statusText}`);
        }

        return "User status updated successfully.";
    } catch (error) {
        console.error("Error updating user status:", error);
        throw error;
    }
};

export const generateOtp = async (email) => {
    try {
        const response = await fetch(`${API_BASE_URL}/generate-otp?email=${email}`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        });

        const result = await response.json();

        if (!response.ok) {
            const message = typeof result.detail === 'string'
                ? result.detail
                : (result.detail && result.detail.msg) || 'Failed to generate OTP';
            throw new Error(message);
        }

        return result; // Return OTP response for further use
    } catch (error) {
        console.error("Error generating OTP:", error);
        throw error;
    }
};

export const verifyOtp = async (email, userOtp) => {
    try {
        const response = await fetch(`${API_BASE_URL}/verify-otp?email=${email}&user_otp=${userOtp}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const result = await response.json();

        if (!response.ok || result.msg !== 'SUCCESS') {
            throw new Error('OTP verification failed');
        }

        return result; // Contains auth_token and possibly other data
    } catch (error) {
        console.error('Error verifying OTP:', error);
        throw error;
    }
};

export const resendOtp = async (email) => {
    try {
        const response = await fetch(`${API_BASE_URL}/generate-otp?email=${email}`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        });

        const result = await response.json();

        if (!response.ok) {
            const message = typeof result.detail === 'string'
                ? result.detail
                : (result.detail && result.detail.msg) || 'Failed to generate OTP';
            throw new Error(message);
        }

        return result; // Return result if needed for additional processing
    } catch (error) {
        console.error('Error resending OTP:', error);
        throw error;
    }
};


export const logoutUser = async (email) => {
    try {
        const response = await fetch(`${API_BASE_URL}/signout?email=${email}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to log out.');
        }

        return response.json();
    } catch (error) {
        console.error('Error during logout:', error);
        throw error;
    }
};


export const addUser = async (new_user_email, is_admin, added_by) => {
    try {
        const response = await fetch(`${API_BASE_URL}/add-user?email=${new_user_email}&added_by=${added_by}&is_admin=${is_admin}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to add user.');
        }

        return response.json();
    } catch (error) {
        console.error('Error during adding user:', error);
        throw error;
    }
};

export const addFile = async (file) => {
    try {
        
        const response = await fetch(`${API_BASE_URL}/add_file`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(file),
        });

        if (!response.ok) {
            throw new Error('Failed to add user.');
        }

        return response.json();
    } catch (error) {
        console.error('Error during adding user:', error);
        throw error;
    }
};

export const change_status = async (builder_name,timestamp,status) => {
    try {
        const json_data = {'builder_name':builder_name,'timestamp':timestamp,'status':status}
        
        const response = await fetch(`${API_BASE_URL}/change_status`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(json_data),
        });

        if (!response.ok) {
            throw new Error('Failed to add user.');
        }

        return response.json();
    } catch (error) {
        console.error('Error during adding user:', error);
        throw error;
    }
}

export const deleteFile = async (builder_name,timestamp) => {
    try {
        const json_data = {'builder_name':builder_name,'timestamp':timestamp}
        
        const response = await fetch(`${API_BASE_URL}/delete_file`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(json_data),
        });

        if (!response.ok) {
            throw new Error('Failed to add user.');
        }

        return response.json();
    } catch (error) {
        console.error('Error during adding user:', error);
        throw error;
    }
};

export const retrieve_all_builders = async () => {
    console.log(`${API_BASE_URL}/retrieve_all_builders`)
    try {
        const response = await fetch(`${API_BASE_URL}/retrieve_all_builders`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to add user.');
        }

        return response.json();
    } catch (error) {
        console.error('Error during adding user:', error);
        throw error;
    }
};

export const addTestCase = async (batchName,arg_description ,fileNames, pdfData) => {
    try {
        const response = await fetch(`${API_BASE_URL}self_eval_test_cases_api/test-case/add`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                batch_name: batchName,
                description:arg_description,
                file_names: fileNames,
                pdf_data: pdfData,
            }),
        });

        if (!response.ok) {
            throw new Error(`Error adding/updating test case: ${response.statusText}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Error adding/updating test case:", error);
        throw error;
    }
};

export const deleteTestCase = async (testCaseName) => {
    try {
        const response = await fetch(`${API_BASE_URL}self_eval_test_cases_api/test-case/delete/${testCaseName}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Error deleting test case: ${response.statusText}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Error deleting test case:", error);
        throw error;
    }
};

export const getAllTestCases = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}self_eval_test_cases_api/test-cases/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Error fetching test cases: ${response.statusText}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Error fetching test cases:", error);
        throw error;
    }
};

export const getTestCase = async (testCaseName) => {
    try {
        const response = await fetch(`${API_BASE_URL}self_eval_test_cases_api/test-case/${testCaseName}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Error fetching test case: ${response.statusText}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Error fetching test case:", error);
        throw error;
    }
};


export const add_order_document = async (file) => {
    try {
        const response = await fetch(`${API_BASE_URL}auth/add_document`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(file),
        });
        console.log(`Response: ${response.statusText}`)
        if (!response.ok) {
            throw new Error(`Error adding file: ${response.statusText}`);
        }

        return response.json();
    } catch (error) {
        console.error("Error adding file:", error);
        throw error;
    }
};



// Verify magic link token
export const verifyToken = async (email, token) => {
    try {
        const response = await fetch(`${API_BASE_URL}/verify-token?email=${encodeURIComponent(email)}&token=${encodeURIComponent(token)}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.detail || 'Failed to verify token.');
        }

        const result = await response.json();
        return result; // Contains the verification status and message
    } catch (error) {
        console.error('Error verifying token:', error);
        throw error;
    }
};


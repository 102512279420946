import React, { useState } from "react";
import Header from "./common/Header";
import { addTestCase } from "../api";

const data = [
  {
    id: 1,
    address: "Chesmar: 123 Maple Street Springfield, IL",
    date: "10/02/24",
    status: "New",
    class: "",
    qa_status: "Start QA",
    files: [
      { name: "PO filename 1", date: "10/02/24" },
      { name: "Selection sheet 1", date: "10/02/24" },
    ],
  },
];

const AddTestCases = () => {
  const [batchName, setBatchName] = useState("");
  const [description, setDescription] = useState(""); // State for description
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isAddFileVisible, setAddFileVisible] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
  };

  const toggleRowExpansion = (id) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleSubmit = async () => {
    try {
      const fileInput = document.querySelector('input[type="file"]');
      const files = fileInput.files;

      if (!files || files.length === 0) {
        alert("Please upload at least one file.");
        return;
      }

      const fileNames = [];
      const pdfData = [];

      for (const file of files) {
        fileNames.push(file.name);
        const fileContent = await fileToBase64(file);
        pdfData.push(fileContent);
      }

      const payload = {
        batch_name: batchName,
        description: description, // Include description in payload
        file_names: fileNames,
        pdf_data: pdfData,
      };

      console.log("Payload:", payload);

      const response = await addTestCase(batchName, description, fileNames, pdfData);
      alert("Batch added successfully!");
    } catch (error) {
      console.error("Error:", error);
      alert(`Failed to add batch: ${error.message}`);
    }
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  return (
    <div>
      <Header />
      <main>
		<div className="pof2frms-container">
        {isAddFileVisible &&<div className="pof2frms-popup-wrapper">
            <div className="pof2frms-popup-container">
              <h2>Upload Batch Files</h2>
              <input
                type="text"
                placeholder="Enter Batch Name"
                value={batchName}
                onChange={(e) => setBatchName(e.target.value)}
              />
              <textarea
                placeholder="Enter Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)} // Description input
              />
              <input type="file" accept=".pdf" multiple onChange={handleFileChange} />
              <div className="pof2frms-popup-action">
                <button
                  type="button"
                  className="pof2frms-cancel"
                  onClick={() => setAddFileVisible(false)}
                >
                  Cancel
                </button>
                <button type="button" className="pof2frms-confirm" onClick={handleSubmit}>
                  Confirm
                </button>
              </div>
            </div>
          </div>}

        <div className="po2frms-table">
          <table>
            <thead>
              <tr>
                <th></th>
                <th>Batch Name</th>
                <th>Date Added</th>
                <th>
                  Status <label><input type="checkbox" /> Show Completed</label>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.map((row) => (
                <React.Fragment key={row.id}>
                  <tr>
                    <td>
                      <input type="radio" />
                    </td>
                    <td
                      className={`po2frms-expand ${expandedRows[row.id] ? "expanded" : ""}`}
                      onClick={() => toggleRowExpansion(row.id)}
                      style={{ cursor: "pointer" }}
                    >
                      {row.address}
                    </td>
                    <td>{row.date}</td>
                    <td className={`po2frms-active ${row.class}`}>{row.status}</td>
                    <td className="actions"></td>
                  </tr>
                  {expandedRows[row.id] && (
                    <tr className="po2frms-expandable-td po2frms-expandable-toggle">
                      <td colSpan="5">
                        <table>
                          <tbody>
                            <tr className="thead">
                              <td>File</td>
                              <td></td>
                              <td></td>
                            </tr>
                            {row.files.map((file, index) => (
                              <tr key={index}>
                                <td>{file.name}</td>
                                <td>{file.date}</td>
                                <td></td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
		</div>
      </main>
    </div>
  );
};

export default AddTestCases;

import React, { useState, useEffect } from 'react';
import '../assets/css/style.css';
import {resendOtp, verifyOtp} from "../api";
import { Link } from 'react-router-dom';

const EmailScreen = ({ onBack, email }) => {

	return (
		<div className="po2rfms">
			<div className="po2rfms-login-form po2rfms-opt-form" id="po2rfms-opt-form" >
				<button
					aria-label="back to login form"
					className="po2rfms-back-btn"
					type="button"
					onClick={onBack}
				></button>
				<div className='po2rfms-mail-icon'>
				<svg width="50" height="36" viewBox="0 0 50 36" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M1.12069 2.21985C0.421848 3.14448 0 4.28659 0 5.51726V30.3448C0 33.3592 2.50284 35.8621 5.51724 35.8621H44.1379C47.1523 35.8621 49.6552 33.3592 49.6552 30.3448V5.51726C49.6552 4.39111 49.3071 3.33645 48.7123 2.45692L26.4601 18.777C25.983 19.1271 25.4059 19.3145 24.8141 19.3115C24.2223 19.3086 23.6471 19.1154 23.1735 18.7605L1.12069 2.21985Z" fill="#C29C61"/>
					<path fill-rule="evenodd" clip-rule="evenodd" d="M7.35449 0L24.8437 13.1196L42.7316 0H7.35449Z" fill="#C29C61"/>
					</svg>
				</div>
				<h3>Check your mailbox!</h3>
				<p>We sent a link to your email. Click on the link to login</p>
				<Link to={"/login"}>Resend Email</Link>
				
			</div>
		</div>
	);
};

export default EmailScreen;

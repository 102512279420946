import React from "react";

const ScoringNotify = ({ sendDataToParent }) => {
	const sendMessage = (e) => {
		e.preventDefault();
        // Send data to parent
        sendDataToParent(true);
    };
  return (
    <div className="pof2frms-scoring-notify">
      <h3>Self-evaluation completed</h3>
      <a href="#" className="pof2frms-popup-close" aria-label="close-popup"></a>
    </div>
  );
};

export default ScoringNotify;

import React from "react";

const ScoringNotify = ({ sendDataToParent , selectedBuilder, setScoreVisible, message}) => {
  const handleClose = () => {
    setScoreVisible(false); // Trigger the state update in the parent
  };
  const sendMessage = (e) => {
    e.preventDefault();
        // Send data to parent
        sendDataToParent(true);
    };
  return (
    <div className="pof2frms-scoring-notify">
      <h3>Scoring Complete</h3>
      <h4>{message}</h4>
      <p>
        File: Builder {selectedBuilder} <a href="/"  onClick={sendMessage}>View</a>
      </p>
      <a onClick={handleClose} className="pof2frms-popup-close" aria-label="close-popup"></a>
    </div>
  );
};

export default ScoringNotify;

import React, { useState, useEffect, useCallback } from 'react';
import Header from "./common/Header";
import StatusBox from './StatusBox';
import EvaluationNotify from './EvaluationNotify';
import MultiCheckDropdown from './MultiCheckDropdown';
import LineChart from './TestCaseChart';
import ClockIcon from '../assets/images/clock-icon.svg';
import PencilIcon from '../assets/images/pencil-icon.svg';

const Dashboard = () => {
  const [isEvaluationPopupVisible, setEvaluationPopupVisible] = useState(false);
  const [isScoreComple, setScoreComple] = useState(false);
  const [isTestCaseVisible, setTestCaseVisible] = useState(false);

  const closeEvaluationPopup = (e) => {
    e.preventDefault();
    setEvaluationPopupVisible(false);
    setScoreComple(true);
    setTimeout(() => {
      setScoreComple(false);
    }, 1500);
  };

  const openEvaluationPopup = (e) => {  // Fixed function name
    e.preventDefault();
    setEvaluationPopupVisible(true);
  };

  const closeTestCasePopup = () => {
    setTestCaseVisible(false);
  };
  const handleViewDetailsClick = useCallback((e) => {  // Wrapped in useCallback
    e.preventDefault();
    setTestCaseVisible(true);
  }, []);

  return (
    <div>
      <Header />
      <main>
        <div className="pof2frms-container">
          <div className="pof2frms-head">
            {isScoreComple && <EvaluationNotify />}
            <h1>Dashboard</h1>
          </div>
		  <div class="pof2frms-statuses">
		 	 <StatusBox number="12" unit="min" text="Avg time to process batch last 30 days" icon={ClockIcon} color={"green"} />
		 	 <StatusBox number="15" unit="corrections" text="Median manual corrections per batch last 30 days" icon={PencilIcon} color={"blue"} />

		  </div>
		  <div className='pof2frms-selfEvaluationScore'>
			<h2>Self Evaluation Scores</h2>
          <div className="pof2frms-builder">
            <span>Builders</span>
            <MultiCheckDropdown />
          </div>
		  </div>
          <div className="chartWrapper">
            {/* Pass handleViewDetailsClick to the TestCaseChart as a prop */}
            <LineChart view={handleViewDetailsClick} />
          </div>
        </div>
      </main>

      {isEvaluationPopupVisible && (
        <div className="pof2frms-popup-wrapper pof2frms-popup-center" id="score-file">
          <div className="popup-overlay" onClick={closeEvaluationPopup}></div>
          <div className="pof2frms-popup-container">
            <h3>Performing Self Evaluation</h3>
            <p>This will take about 10-15 minutes.<br />We will notify you when scoring is complete</p>
            <div className="pof2frms-popup-action">
              <button type="button" className="pof2frms-activated" onClick={closeEvaluationPopup}>
                Okay
              </button>
            </div>
          </div>
        </div>
      )}

      {isTestCaseVisible && (
        <div className="pof2frms-popup-wrapper pof2frms-popup-testcase" id="compare-file">
          <div className="popup-overlay" onClick={closeTestCasePopup}></div>
          <div className="pof2frms-popup-container">
            <a href="#" className="pof2frms-popup-close" aria-label="close-popup" onClick={closeTestCasePopup}></a>
            <h3>Failed Test Cases</h3>
			<p>Builders: All | Score - 90</p>
            <div className="po2frms-table">
              <table>
                <thead>
                  <tr>
                    <th>File Name</th>
                    <th>Test Case</th>
                    <th>Problem</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Builder A</td>
                    <td>Testcase 1</td>
                    <td>Lorem Ipsum is simply dummy text..</td>
                  </tr>
                  <tr>
                    <td>Builder A</td>
                    <td>Testcase 2</td>
                    <td>Lorem Ipsum is simply dummy text..</td>
                  </tr>
                  <tr>
                    <td>Builder B</td>
                    <td>Testcase 3</td>
                    <td>Lorem Ipsum is simply dummy text..</td>
                  </tr>
                  <tr>
                    <td>Builder C</td>
                    <td>Testcase 4</td>
                    <td>Lorem Ipsum is simply dummy text..</td>
                  </tr>
                </tbody>
              </table>
            </div>
            
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;

import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { isAuthenticated } from '../utils/auth';  // Your existing auth logic
import { verifyToken } from '../api';      // Import the verifyToken function

const PublicRoute = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const email = queryParams.get('email');

    if (token && email) {
      setIsLoading(true);

      // Call the verifyToken API function
      verifyToken(email, token)
        .then((result) => {
          setIsLoading(false);
          console.log(result)
          if (result && result.authToken) {
            // Successfully verified the token
            localStorage.setItem('authToken', result.authToken);
            localStorage.setItem('is_admin', result.is_admin);  // Save the auth token
            localStorage.setItem('loggedInUserEmail', result.email);  // Save the email
            
            if(result.is_admin)
              {
                navigate('/dashboard', { state: { message: 'Login Successfull' } });
              }
            else{
              navigate('/orders-lisiting', { state: { message: 'Login Successfull' } });

            }
          } 
          else {
            // Invalid token
            navigate('/login', { state: { message: 'Invalid login link' } });
          }
        })
        .catch(() => {
          setIsLoading(false);
          navigate('/login', { state: { message: 'Invalid login link' } });  // Redirect on failure
        });
    }
  }, [location.search, navigate]);

  return children;
};

export default PublicRoute;

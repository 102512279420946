import React, {useState} from 'react';
import '../assets/css/style.css';
import {generateMagicLink, generateOtp} from "../api";

const EmailLogin = ({onCodeRequest}) => {
    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const handleInputChange = (e) => {
        const emailValue = e.target.value;
        setEmail(emailValue);
        setIsValidEmail(emailRegex.test(emailValue)); // Validate email format
        setErrorMessage(''); // Clear error message on input change
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isValidEmail) {
            try {
                await generateMagicLink(email);
                onCodeRequest(email); // Transition to EMAILLOGIN screen
            } catch (error) {
                setErrorMessage(error.message || 'An error occurred. Please try again.');
            }
        } else {
            setErrorMessage("Please enter a valid email address.");
        }
    };

    return (
        <div className="po2rfms">
            <form onSubmit={handleSubmit} className="po2rfms-login-form" id="po2rfms-login-form">
                <h2>Login</h2>
                <p>Welcome! Please enter your email to receive a login code</p>
                <div className="po2rfms-fieldbox">
                    <label htmlFor="email">Email</label>
                    <input
                        type="text"
                        name="email"
                        id="email"
                        value={email}
                        onChange={handleInputChange}
                    />
                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                </div>
                <button type="submit" disabled={!isValidEmail}>
                    Send verification code
                </button>
            </form>
        </div>
    );
};

export default EmailLogin;

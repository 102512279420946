import React, {useState, useEffect, useCallback} from 'react';
import Header from "./common/Header";
import yaml from "js-yaml";
import {changeUserRole, changeUserStatus, fetchUsers,TestOrdersListingRun, add_order_document} from "../api";
import { useNavigate } from 'react-router-dom';



const DataTable = () => {
	const data = [
		{
		  id: 1,
		  address: "Chesmar: 123 Maple Street Springfield, IL",
		  date: "10/02/24",
		  status: "New",
		  class: '',
		  qa_status: 'Start QA',
		  files: [
			{ name: "PO filename 1", date: "10/02/24" },
			{ name: "Selection sheet 1", date: "10/02/24" },
		  ],
		},
		{
		  id: 2,
		  address: "Greenfield: 456 Oak Avenue Austin, TX",
		  date: "10/05/24",
		  status: "Analyst QA",
		  qa_status: 'Continue QA',
		  class: 'qa',
		  files: [
			{ name: "PO filename 2", date: "10/05/24" },
			{ name: "Selection sheet 2", date: "10/05/24" },
		  ],
		},
		{
		  id: 3,
		  address: "Sunset Homes: 789 Pine Road Miami, FL",
		  date: "10/10/24",
		  status: "Error",
		  class: 'error',
		  qa_status: 'Continue QA',
		  files: [
			{ name: "PO filename 3", date: "10/10/24" },
			{ name: "Selection sheet 3", date: "10/10/24" },
		  ],
		},
		{
		  id: 4,
		  address: "Maple Realty: 101 Elm Street Denver, CO",
		  date: "10/12/24",
		  status: "New",
		  class: '',
		  qa_status: 'Start QA',
		  files: [
			{ name: "PO filename 4", date: "10/12/24" },
			{ name: "Selection sheet 4", date: "10/12/24" },
		  ],
		},
		{
		  id: 5,
		  address: "Lakeside: 202 Cedar Court Portland, OR",
		  date: "10/15/24",
		  status: "Analyst QA",
		  class: 'qa',
		  qa_status: 'Continue QA',
		  files: [
			{ name: "PO filename 5", date: "10/15/24" },
			{ name: "Selection sheet 5", date: "10/15/24" },
		  ],
		},
		{
		  id: 6,
		  address: "River Bend: 303 Birch Drive Seattle, WA",
		  date: "10/18/24",
		  status: "Error",
		  class: 'error',
		  qa_status: 'Continue QA',
		  files: [
			{ name: "PO filename 6", date: "10/18/24" },
			{ name: "Selection sheet 6", date: "10/18/24" },
		  ],
		},
		{
		  id: 7,
		  address: "Sunnydale: 404 Palm Blvd Los Angeles, CA",
		  date: "10/20/24",
		  status: "New",
		  class: '',
		  qa_status: 'Start QA',
		  files: [
			{ name: "PO filename 7", date: "10/20/24" },
			{ name: "Selection sheet 7", date: "10/20/24" },
		  ],
		},
		{
		  id: 8,
		  address: "Mountain Homes: 505 Willow Ln Boulder, CO",
		  date: "10/25/24",
		  status: "Analyst QA",
		  class: 'qa',
		  qa_status: 'Continue QA',
		  files: [
			{ name: "PO filename 8", date: "10/25/24" },
			{ name: "Selection sheet 8", date: "10/25/24" },
		  ],
		},
		{
		  id: 9,
		  address: "Oceanview: 606 Bay Street San Diego, CA",
		  date: "10/28/24",
		  status: "Error",
		  class: 'error',
		  qa_status: 'Continue QA',
		  files: [
			{ name: "PO filename 9", date: "10/28/24" },
			{ name: "Selection sheet 9", date: "10/28/24" },
		  ],
		},
		{
		  id: 10,
		  address: "Crestview: 707 Maple Avenue Boston, MA",
		  date: "10/30/24",
		  status: "New",
		  class: '',
		  qa_status: 'Start QA',
		  files: [
			{ name: "PO filename 10", date: "10/30/24" },
			{ name: "Selection sheet 10", date: "10/30/24" },
		  ],
		},
	  ];
	const [isDeletePopupVisible, setDeletePopupVisible] = useState(false);
	const [isaddFileVisible, setaddFileVisible] = useState(false);
	const [isQaPopupVisible, setQaPopupVisible] = useState(false);
	const [initialdata, setData] = useState(data);



	const navigate = useNavigate();

	// Function to handle the click event
	// const handleClick = (e) => {
	// 	e.preventDefault(); // Prevent the default link behavior
	// 	if (row.qa_status === 'Start QA') {
	// 	  navigate('/perform/qa');
	// 	}
	//   };

	const closeAddPopup = () => {
        setaddFileVisible(false);
	};
	
	const handleDeleteClick = (e) => {
		e.preventDefault();
		setDeletePopupVisible(true);
	  };
	
	  const closeDeletePopup = (e) => {
		e.preventDefault();
		setDeletePopupVisible(false);
	  };
	  const handleAddFileClick = (e) => {
		e.preventDefault();
		setaddFileVisible(true);
	  };
	  const closeSystemPopup =() =>{
		setaddFileVisible(false);
		handleQaShow(true);
	  }
	  const handleQaShow = (e) => {
		setQaPopupVisible(true);
	  };
	  const closeQAopup =(e) =>{
		e.preventDefault();
		setQaPopupVisible(false);
	  }
  const [expandedRows, setExpandedRows] = useState({});

  const toggleRowExpansion = (id) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  useEffect(() => {
	// Initialize WebSocket connection
	const ws = new WebSocket("wss://eaglepoint.wpbrigade.com/auth/ws");

	ws.onmessage = (event) => {
		console.log(`EVENT DATA: ${event.data}`)
		if(event.data == 'Process started')
		{
			
			const newItem = {
				id: 1,
				address: "Chesmar: 123 Maple Street Springfield, IL",
				date: "10/02/24",
				status: "New",
				class: '',
				qa_status: 'Processing',
				files: [
				  { name: "PO filename 1", date: "10/02/24" },
				  { name: "Selection sheet 1", date: "10/02/24" },
				],
			}
			setData((prevData) => [...prevData, newItem]);
			setData((prevData) => {

			const index = prevData.findIndex((builder) => builder.qa_status === 'Processing');
				if (index !== -1) {
					return [
						...prevData.slice(0, index),
						{ ...prevData[index], qa_status: 'Processing', class: 'loading'  },
						...prevData.slice(index + 1),
					];
				}
				return prevData;
				});

			console.log(`Process Started`)
			
			console.log(JSON.stringify(initialdata, null, 2));
			// setCompileStartPopupVisible(true);
		}
		else if (event.data == 'Process Order Complete'){
			console.log(`Process Complete`)
			setData((prevData) => {

				const index = prevData.findIndex((builder) => builder.qa_status === 'Processing');
					if (index !== -1) {
						return [
							...prevData.slice(0, index),
							{ ...prevData[index], qa_status: 'Start QA', class: ''  },
							...prevData.slice(index + 1),
						];
					}
					return prevData;
					});
		}

	};

	ws.onerror = (error) => {
		console.error("WebSocket error:", error);
	};

	ws.onclose = () => {
		console.warn("WebSocket connection closed");
	};
	ws.onopen = () => {
		console.log("WebSocket connection established");
	};

	return () => {
		// Cleanup WebSocket connection on component unmount
		// ws.close();
	};
}, []);

  return (
    <div>
      <Header/>
            <main>
	<svg style={{display: 'none'}}>
    <symbol id="icon-edit" width="20" height="20" viewBox="0 0 20 20">
        <path d="M17.4388 4.5917L15.4083 2.56127C14.6612 1.81291 13.4403 1.81291 12.6931 2.56127L12.5941 2.46228L12.594 2.46236L2.08936 12.9682C2.08936 12.9683 2.08936 12.9683 2.08936 12.9683C1.94367 13.1139 1.86 13.3112 1.86 13.5194V17.3596C1.86 17.7907 2.20931 18.14 2.64042 18.14H6.48064C6.68805 18.14 6.88556 18.0581 7.03178 17.9119L7.03179 17.9119L17.5378 7.40593C17.9262 7.0175 18.1412 6.49858 18.1412 5.94932C18.1412 5.39881 17.9261 4.88111 17.5378 4.49271L17.4388 4.5917ZM17.4388 4.5917L17.5378 4.4927L15.5074 2.46236M17.4388 4.5917L15.5074 2.46236M15.5074 2.46236C15.5074 2.46233 15.5074 2.4623 15.5073 2.46228L15.5074 2.46236ZM16.4352 5.59505L16.4357 5.59554C16.5298 5.68867 16.5813 5.81333 16.5813 5.94821C16.5813 6.08159 16.53 6.20662 16.4353 6.30136C16.4352 6.30136 16.4352 6.30137 16.4352 6.30137L14.162 8.5746L11.4253 5.83787L13.6973 3.56462C13.6974 3.56462 13.6974 3.56461 13.6974 3.5646C13.892 3.37001 14.2088 3.36964 14.4049 3.56475C14.405 3.56478 14.405 3.56482 14.4051 3.56486L16.4352 5.59505ZM3.42089 13.8423L10.3221 6.94107L13.0588 9.67778L6.1576 16.579H3.42089V13.8423Z" fill="#6D727A" stroke="#6D727A" strokeWidth="0.28"/>
    </symbol>
	<symbol id="icon-block" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.7139 4.3281L15.7074 4.31999L15.6994 4.31338L15.6739 4.29243C14.9267 3.53591 14.0369 2.93496 13.056 2.52431C12.0735 2.113 11.0192 1.9008 9.95408 1.9C8.88898 1.89921 7.83431 2.10983 6.85121 2.51968C5.86812 2.92952 4.97616 3.53042 4.22703 4.28756C3.4779 5.04469 2.8865 5.94299 2.48713 6.93038C2.08776 7.91778 1.88835 8.97462 1.90046 10.0397C1.91257 11.1047 2.13597 12.1567 2.5577 13.1348C2.97908 14.112 3.59008 14.996 4.35535 15.7355C4.35537 15.7355 4.35539 15.7356 4.35541 15.7356L4.36078 15.7427L4.36084 15.7428C4.3656 15.7492 4.37542 15.7622 4.38964 15.7747C4.40638 15.7896 4.42386 15.8037 4.44201 15.8168C5.96251 17.2979 8.00688 18.1182 10.1297 18.0986C12.255 18.0789 14.2862 17.219 15.7796 15.7066C17.273 14.1943 18.1072 12.1523 18.1 10.0269C18.0928 7.90336 17.2462 5.86886 15.745 4.36705L15.7139 4.3281ZM10.0001 3.53341L10.0003 3.53341C11.4546 3.5302 12.8659 4.01959 14.0051 4.92002L4.96674 14.0554C4.21872 13.1283 3.741 12.0118 3.58743 10.8291C3.42913 9.60993 3.62181 8.37092 4.14286 7.25742C4.66392 6.14392 5.49173 5.20211 6.52918 4.5425C7.56662 3.88289 8.77067 3.53284 10.0001 3.53341ZM10.0003 16.4672H10C8.59256 16.468 7.22475 16.0071 6.10562 15.157L15.1202 6.04539C15.8439 6.97842 16.2985 8.09256 16.4336 9.26701C16.573 10.4786 16.3666 11.705 15.8384 12.8042C15.3101 13.9035 14.4816 14.8309 13.4485 15.479C12.4154 16.1272 11.2199 16.4698 10.0003 16.4672Z" fill="#6D727A" stroke="#6D727A" strokeWidth="0.2"/>
</symbol>
<symbol id="icon-plus" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.4167 6.12439H7.87558V0.583319C7.87558 0.42873 7.81399 0.28018 7.70408 0.170289C7.59418 0.0615947 7.44684 0 7.29226 0H6.70773C6.38646 0 6.1244 0.260868 6.1244 0.583325V6.1244H0.583325C0.260853 6.1244 0 6.38648 0 6.70773V7.29226C0 7.44684 0.0615941 7.59419 0.170289 7.70408C0.280192 7.81398 0.428743 7.87558 0.583319 7.87558H6.1244V13.4179V13.4167C6.1244 13.5713 6.18599 13.7198 6.29589 13.8297C6.4058 13.9384 6.55314 14 6.70772 14H7.29225C7.44684 14 7.59418 13.9384 7.70407 13.8297C7.81398 13.7198 7.87557 13.5713 7.87557 13.4167V7.87561H13.4179H13.4167C13.5713 7.87561 13.7198 7.81401 13.8297 7.70411C13.9384 7.5942 14 7.44686 14 7.29228V6.70775C14 6.55316 13.9384 6.40582 13.8297 6.29593C13.7198 6.18603 13.5712 6.12439 13.4167 6.12439Z" fill="currentColor"/>
</symbol>
<symbol id="icon-close" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.4407 17.8802C15.4408 17.8802 15.4408 17.8803 15.4408 17.8803C15.5739 18.0132 15.7757 18.0353 15.9317 17.9468L15.6561 17.6712C15.655 17.6702 15.6539 17.6692 15.6529 17.6681C15.6528 17.668 15.6527 17.668 15.6527 17.6679L15.4407 17.8802ZM15.4407 17.8802L15.4408 17.8802L15.4407 17.8802ZM0.331912 2.34729C0.290453 2.30573 0.289391 2.23909 0.328725 2.19625L0.332017 2.19295L2.19295 0.332021L2.19315 0.331825C2.21301 0.31192 2.23991 0.30047 2.2683 0.300011C2.29634 0.299613 2.32339 0.309992 2.34388 0.328792L2.34706 0.331978L8.78649 6.77397L8.99863 6.98618L9.2108 6.77401L15.6527 0.332115L0.331874 15.6529M0.331912 2.34729L0.331832 2.34721L0.119748 2.55938L0.331969 2.34735M0.331912 2.34729C0.331931 2.34731 0.33195 2.34733 0.331969 2.34735M0.331912 2.34729L0.331969 2.34735M0.331969 2.34735L6.77382 8.78664L6.98604 8.99877L6.77386 9.21095L0.331874 15.6529M0.331874 15.6529C0.289459 15.6953 0.289292 15.7644 0.331874 15.8071M0.331874 15.6529V15.8071M0.331874 15.8071L0.331969 15.8072M0.331874 15.8071L0.331969 15.8072M0.331969 15.8072L2.10494 17.5802H2.10516L2.19305 17.6681C2.23547 17.7106 2.30455 17.7107 2.34725 17.668L0.331969 15.8072ZM11.4382 8.99881L11.6503 8.78668L11.6503 8.78672L11.4382 8.99881ZM11.4382 8.99881L11.6503 9.21099L11.6503 9.21095L11.4382 8.99881ZM8.99881 11.4382L9.21099 11.6503L9.21094 11.6503L8.99881 11.4382ZM8.99881 11.4382L8.78668 11.6503L8.78672 11.6503L8.99881 11.4382ZM6.56173 8.99881L6.3496 9.21095L6.34964 9.21099L6.56173 8.99881ZM6.56173 8.99881L6.34956 8.78672L6.3496 8.78668L6.56173 8.99881ZM15.4407 17.88L15.4407 17.8801L15.1408 17.5802L15.4407 17.88ZM17.668 15.6529C17.7094 15.6943 17.7106 15.7609 17.6712 15.8038L15.6528 0.332021C15.6955 0.289439 15.7645 0.289605 15.8069 0.332021L15.8948 0.419889H15.895L17.6679 2.19286C17.7105 2.23552 17.7105 2.30468 17.6679 2.34735L11.2261 8.78664L11.0139 8.99877L11.226 9.21095L17.668 15.6529Z" fill="#0C1421" />
</symbol>
<symbol id="download-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.0174 13.9323L10.0528 13.9676L10.0881 13.9323L15.1945 8.82592L15.1946 8.82591C15.5634 8.45697 15.5634 7.85881 15.1946 7.48988L15.1945 7.48987C14.8255 7.12093 14.2273 7.12093 13.8583 7.48987L13.8937 7.52523L13.8583 7.48987L10.9975 10.3508V1.89474C10.9975 1.37298 10.5745 0.95 10.0528 0.95C9.53099 0.95 9.10801 1.37298 9.10801 1.89474V10.3508L6.24709 7.48987C5.87815 7.12091 5.27998 7.12091 4.91104 7.48987C4.54209 7.85881 4.54209 8.45698 4.91104 8.82592L10.0174 13.9323Z" fill="#6D727A" stroke="#6D727A" strokeWidth="0.1"/>
<path d="M3.8396 13.5263C3.8396 13.0046 3.41662 12.5816 2.89486 12.5816C2.3731 12.5816 1.95012 13.0046 1.95012 13.5263V14.9579C1.95012 16.6657 3.33451 18.05 5.04223 18.05H15.0633C16.771 18.05 18.1554 16.6657 18.1554 14.9579V13.5263C18.1554 13.0046 17.7323 12.5816 17.2106 12.5816C16.689 12.5816 16.2659 13.0046 16.2659 13.5263V14.9579C16.2659 15.6221 15.7275 16.1605 15.0633 16.1605H5.04223C4.37803 16.1605 3.8396 15.6221 3.8396 14.9579V13.5263Z" fill="#6D727A" stroke="#6D727A" strokeWidth="0.1"/>
</symbol>
<symbol id="speed-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.1825 10.0423C18.0492 10.0605 17.9209 10.1049 17.8048 10.1727C17.6887 10.2406 17.5871 10.3307 17.5058 10.4378C17.4245 10.545 17.3651 10.6671 17.331 10.7972C17.297 10.9273 17.2888 11.0628 17.3072 11.1961C17.5389 12.7844 17.2276 14.4044 16.4237 15.7937C16.2872 16.0283 16.2494 16.3075 16.3187 16.5699C16.388 16.8324 16.5587 17.0565 16.7932 17.1932C16.9484 17.2843 17.1251 17.3323 17.3051 17.3324C17.4846 17.3322 17.661 17.2848 17.8164 17.195C17.9718 17.1051 18.1009 16.976 18.1907 16.8205C19.2261 15.0368 19.629 12.9558 19.3342 10.9145C19.2966 10.6464 19.1543 10.4041 18.9384 10.2406C18.7226 10.0771 18.4508 10.0058 18.1825 10.0423Z" fill="#6D727A"/>
<path d="M10.2137 5.04749C10.5003 5.04749 10.7952 5.06694 11.0695 5.09151C11.3916 5.13141 11.7112 5.18952 12.0267 5.26555C12.1591 5.30074 12.2971 5.30904 12.4327 5.28994C12.5683 5.27084 12.6986 5.22474 12.8161 5.15436C12.9335 5.08398 13.0357 4.99076 13.1165 4.8802C13.1973 4.76965 13.255 4.64402 13.2864 4.51074C13.3178 4.37746 13.3221 4.23924 13.2991 4.10426C13.2761 3.96928 13.2263 3.84029 13.1525 3.72491C13.0788 3.60954 12.9827 3.51013 12.8698 3.43256C12.757 3.355 12.6297 3.30086 12.4956 3.27334C12.0955 3.17687 11.6901 3.10406 11.2815 3.05528C10.9211 3.0215 10.5618 3 10.2137 3C7.77098 3.00298 5.42917 3.97466 3.70191 5.70192C1.97465 7.42918 1.00297 9.77099 0.999985 12.2137C0.992131 13.8313 1.41623 15.4217 2.22848 16.8206C2.3652 17.0538 2.58862 17.2234 2.84998 17.2925C3.11133 17.3616 3.38941 17.3246 3.62353 17.1894C3.85765 17.0542 4.0288 16.8319 4.09963 16.571C4.17046 16.3101 4.13522 16.0318 4.00161 15.7968C3.37017 14.7087 3.04078 13.4717 3.04747 12.2137C3.04964 10.3138 3.80535 8.49228 5.1488 7.14882C6.49226 5.80536 8.31376 5.04966 10.2137 5.04749Z" fill="#6D727A"/>
<path d="M16.056 6.37127C15.864 6.17935 15.6037 6.07153 15.3322 6.07153C15.0607 6.07153 14.8004 6.17935 14.6084 6.37127L10.7376 10.2421C10.2946 10.1189 9.82326 10.1458 9.39718 10.3188C8.97111 10.4918 8.61432 10.801 8.38252 11.1981C8.15073 11.5953 8.05698 12.058 8.11593 12.514C8.17487 12.9701 8.38319 13.3938 8.70835 13.7189C9.0335 14.0441 9.45719 14.2524 9.91324 14.3113C10.3693 14.3703 10.832 14.2765 11.2292 14.0447C11.6263 13.813 11.9355 13.4562 12.1085 13.0301C12.2814 12.604 12.3084 12.1327 12.1852 11.6896L16.056 7.81885C16.2479 7.62687 16.3557 7.36652 16.3557 7.09506C16.3557 6.8236 16.2479 6.56325 16.056 6.37127Z" fill="#6D727A"/>
</symbol>
<symbol id="trash-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.85686 7.20209C8.85708 6.95367 8.762 6.762 8.61534 6.63331C8.47013 6.5059 8.27874 6.4439 8.08993 6.4439C7.90111 6.4439 7.70968 6.5059 7.56439 6.63329C7.41764 6.76196 7.3224 6.9536 7.3224 7.20203V15.1881C7.3224 15.4367 7.41756 15.6285 7.56424 15.7573C7.70947 15.8848 7.90084 15.9468 8.08963 15.9468C8.27842 15.9468 8.46979 15.8848 8.61502 15.7573C8.7617 15.6285 8.85686 15.4367 8.85686 15.1881V7.20209ZM8.85686 7.20209L8.78186 7.20203H8.85686V7.20209ZM4.26882 3.31495H4.26879L2.4972 3.31576C2.49718 3.31576 2.49717 3.31576 2.49716 3.31576C2.24859 3.31577 2.05682 3.41093 1.92805 3.5576C1.80054 3.70283 1.73848 3.8942 1.73848 4.08299C1.73848 4.27178 1.80054 4.46315 1.92805 4.60838C2.05683 4.75506 2.24861 4.85022 2.4972 4.85022H3.50172L3.50094 15.6307C3.50094 17.5271 5.04884 19.075 6.94519 19.075H13.0002C14.8965 19.075 16.4444 17.5263 16.4444 15.6307V4.84941H17.4498C17.6983 4.84941 17.8901 4.75425 18.0189 4.60757C18.1464 4.46234 18.2085 4.27097 18.2085 4.08218C18.2085 3.89339 18.1464 3.70202 18.0189 3.55679C17.8901 3.41011 17.6983 3.31495 17.4498 3.31495H12.8298C12.7226 2.7207 12.434 2.18796 12.0235 1.77741C10.3465 0.099754 7.52116 1.0479 7.11598 3.31495H4.26882ZM11.0885 7.20203V15.1881C11.0885 15.4367 11.1836 15.6285 11.3303 15.7573C11.4755 15.8848 11.6669 15.9468 11.8557 15.9468C12.0445 15.9468 12.2358 15.8848 12.3811 15.7573C12.5278 15.6285 12.6229 15.4367 12.6229 15.1881V7.20203C12.6229 6.95362 12.5277 6.76197 12.381 6.6333C12.2358 6.5059 12.0445 6.4439 11.8557 6.4439C11.6669 6.4439 11.4755 6.5059 11.3303 6.6333C11.1836 6.76197 11.0885 6.95362 11.0885 7.20203ZM11.2406 3.31495H8.70568C9.15882 2.18355 10.7874 2.18355 11.2406 3.31495ZM14.9101 4.8502L14.9107 15.6315C14.9107 16.6807 14.0501 17.5414 13.0009 17.5414H6.94588C5.89669 17.5414 5.03604 16.68 5.03604 15.6315V4.8502H14.9101Z" fill="#6D727A" stroke="#6D727A" strokeWidth="0.15"/>
</symbol>
<symbol id="green-tick" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13 0C5.82783 0 0 5.82783 0 13C0 20.1722 5.82783 26 13 26C20.1722 26 26 20.1722 26 13C26.001 5.82783 20.1732 0 13 0ZM20.5212 9.18427L11.037 19.1063C10.818 19.3253 10.5193 19.4553 10.2196 19.4553C9.93035 19.4553 9.65158 19.3452 9.44305 19.1566L4.65185 14.7928C4.1939 14.3641 4.1635 13.6474 4.57221 13.1789C5.00083 12.7105 5.72811 12.6811 6.19551 13.0993L10.1599 16.7052L18.8569 7.59955C19.295 7.14159 20.0223 7.1311 20.4802 7.56916C20.9403 7.99883 20.9603 8.72631 20.5212 9.18427Z" fill="#168D2A"/>
</symbol>

</svg>
                <div className="pof2frms-container">
                    <div className="pof2frms-head">
                        <h1>Orders</h1>
						<button className="btn" data-open="#add-file" onClick={handleAddFileClick}><svg width="14" height="14"><use href="#icon-plus"></use></svg> Add Documents</button>
                    </div>
                    <div className="po2frms-table">
				<table>
				<thead>
					<tr>
						<th>Batch Name</th>
						<th>Date Added</th>
						<th>Status <label><input type="checkbox"/> Show Completed</label></th>
						<th>Action</th>
					</tr>
				</thead>
	  <tbody>
        {initialdata.map((row) => (
          <React.Fragment key={row.id}>
            <tr>
              <td
                className={`po2frms-expand ${expandedRows[row.id] ? "expanded" : ""}`}
                onClick={() => toggleRowExpansion(row.id)}
                style={{ cursor: "pointer" }}
              >
                {row.address}
              </td>
              <td>{row.date}</td>
              <td className={`po2frms-active ${row.class}`}>{row.status}</td>
              <td className="actions">
			  	<span className="po2frms-qa-status">
					{row.qa_status === 'Start QA' || row.qa_status === 'Continue QA' ? (
						<a href="/perform-qa">
						{row.qa_status}
						</a>
					) : (
						row.qa_status
					)}
				</span>
                <a href="/" data-tooltip="Download">
                  <svg width="20" height="20">
                    <use href="#download-icon"></use>
                  </svg>
                </a>
                <a data-tooltip="Delete" href="#delete-file" onClick={handleDeleteClick}>
                  <svg width="20" height="20">
                    <use href="#trash-icon"></use>
                  </svg>
                </a>
              </td>
            </tr>
            {expandedRows[row.id] && (
              <tr className="po2frms-expandable-td">
                <td colSpan="4">
                  <table>
                    <tbody>
                      <tr className="thead">
                        <td>File</td>
                        <td></td>
                      </tr>
                      {row.files.map((file, index) => (
                        <tr key={index}>
                          <td>{file.name}</td>
                          <td>{file.date}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
	</div>
	</div>
	</main>
	
	{isDeletePopupVisible && (
	  <div className="pof2frms-popup-wrapper" id="delete-file">
		<div className="popup-overlay" onClick={closeDeletePopup}></div>
		<div className="pof2frms-popup-container">
		  <a href="#" className="pof2frms-popup-close" aria-label="close-popup" onClick={closeDeletePopup}></a>
		  <h3>Delete File</h3>
		  <p>Are you sure that you want to delete ‘Builder A’ rules file?</p>
		  <div className="pof2frms-popup-action">
			<button type="button" className="pof2frms-cancel" onClick={closeDeletePopup}>
			  Cancel
			</button>
			<button type="button" className="pof2frms-deactivate">
			  Delete
			</button>
		  </div>
		</div>
	  </div>
	)}
	{isaddFileVisible && (
		<AddOrderFile
		onCancel={closeSystemPopup}
		/>
			
		  )}
	{isQaPopupVisible && (
		<div className="pof2frms-popup-wrapper" id="activate-user">
		<div className="popup-overlay" onClick={closeQAopup}></div>
		<div className="pof2frms-popup-container">
			<a href="/" className="pof2frms-popup-close" aria-label="close-popup" onClick={closeQAopup}></a>
			<h3>Need to Redo QA</h3>
			<p>These files relate to a home in QA. Manual modifications will be reset by this upload</p>
			<div className="pof2frms-popup-action">
				<button type="button" className="pof2frms-cancel" onClick={closeQAopup}>Cancel</button>
				<button type="button">Proceed</button>
			</div>
		</div>
	</div>
	)}
	</div>
  );
  function AddOrderFile({onCancel})
  {
	
	const [fileContent, setFileContent] = useState("");
    const [fileName, setFileName] = useState("");
    const [files, setFiles] = useState([]);
        
    
	const handleFileUpload = (event) => {
		const selectedFiles = event.target.files;
	
		if (selectedFiles.length > 0) {
			const fileReaders = [];
			const fileContents = [];
	
			Array.from(selectedFiles).forEach((file) => {
				const reader = new FileReader();
				fileReaders.push(
					new Promise((resolve, reject) => {
						reader.onload = (e) => {
							try {
								const content = yaml.load(e.target.result);
								fileContents.push({ name: file.name, content });
								resolve();
							} catch (error) {
								reject(error);
							}
						};
						reader.onerror = reject;
						reader.readAsText(file);
					})
				);
			});
	
			Promise.all(fileReaders)
				.then(() => setFiles(fileContents))
				.catch((error) => {
					console.error("Error reading files:", error);
					alert("Error reading files. Please check the file format.");
				});
		}
	};
	
	const handleSubmit = async () => {
		console.log(`Files: ${JSON.stringify(files, null, 2)}`);

		try {
			for (const file of files) {
				const data = await add_order_document(file.content);
				const comiling_response = await TestOrdersListingRun();
			}
			
			
		} catch (error) {
			if (error.response) {
				// This could be an HTTP error with a response (e.g., status code)
				alert(`Failed to add file: ${error.response.data.message || error.response.statusText}`);
			} else if (error.request) {
				// This occurs if no response was received (e.g., network error)
				alert("Network error. Please check your connection and try again.");
			} else {
				// Any other kind of error (e.g., validation, unexpected errors)
				console.log(error);
				alert(`Failed to add file: ${error.message}`);
			}
			}
	};        
	return (
		<div className="pof2frms-popup-wrapper">
			<div className="popup-overlay"/>
			<div className="pof2frms-popup-container">
			<h2>Upload YAML File</h2>
			<div className='pof2frms-popup-file-choose'>
				<label className="pof2frms-popup-select-file">
					Choose File
				</label> 
				{files.length > 0
        			? files.map((file, index) => <div key={index}>{file.name}</div>)
        			: "No files selected"
				}
				<input
					type="file"
					accept=".yaml,.yml"
					multiple
					onChange={handleFileUpload}
				/>
				</div>
				<div className="pof2frms-popup-action">
					<button type="button" className="pof2frms-cancel" onClick={onCancel}>
						Cancel
					</button>
					<button type="button" className="pof2frms-confirm" onClick={handleSubmit}>
						Confirm
					</button>
				</div>
			</div>
		</div>
	);
  }
};

export default DataTable;
